<core-field-container
  [label]="label()"
  [error]="error()"
>
  <p-password
    [ngModel]="value()"
    [toggleMask]="true"
    (ngModelChange)="writeValue($event)"
    [feedback]="feedback()"
    [placeholder]="placeholder()"
    styleClass="w-full border border-border-control rounded
    [&_.p-password-input]:px-2
    [&_.p-password-input]:py-1
    [&_.p-password-input]:bg-transparent
    [&_.p-inputtext]:rounded-none
    [&_.p-password-input]:w-full
    [&_.p-icon-wrapper_path]:fill-border-control"
  />
</core-field-container>
